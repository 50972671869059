import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { pluck } from '../shared/utilities'
import LoadingIcon from './loadingIcon'
import LinksMenu from './LinksMenu'
import List from './List'
import SearchInput from './SearchInput'

const TYPE_KEY = 'locationType'
const isOnline = event => event[TYPE_KEY].includes('Online')

const EventsFilterable = ({ data, title }) => {
  const [eventsFiltered, setEventsFiltered] = useState(data)
  const [selectedValue, setSelectedValue] = useState('All States')

  const STATE_KEY = 'locationState'
  const states = pluck(data, STATE_KEY)
  const locationTypes = pluck(data, TYPE_KEY)
  console.log(locationTypes)
  useEffect(() => {
    setEventsFiltered(data)
  }, [data])

  function filterData(key, value) {
    setEventsFiltered(data.filter(item => isOnline(item) || item[key] === value))
    setSelectedValue(value)
  }

  const resetFilter = () => {
    setEventsFiltered(data)
    setSelectedValue('All States')
  }

  const handleClick = e => {
    const value = e.currentTarget.dataset.id
    filterData(STATE_KEY, value)
  }

  const handleChangeSearchBar = e => {
    const dataListCopy = [...data]
    let searchList = []

    if (e.target.value !== '') {
      searchList = dataListCopy.filter(item => {
        const itemTitle = item.title.toLowerCase()
        const searchText = e.target.value.toLowerCase().trim()
        return itemTitle.includes(searchText)
      })
    } else {
      searchList = [...dataListCopy]
    }
    setEventsFiltered(searchList)
  }

  console.log('eventsFiltered', eventsFiltered)

  return (
    <div>
      {data.length === 0 ? (
        <div className="text-center m-50-auto">
          <LoadingIcon />
        </div>
      ) : (
        <>
          <h2>{title}</h2>
          <SearchInput handleChangeSearchBar={handleChangeSearchBar} />
          <LinksMenu
            title="All States"
            items={states}
            type="state"
            handleClick={handleClick}
            resetFilter={resetFilter}
            selectedValue={selectedValue}
          />
          <div style={{ minHeight: '200px' }}>
            {eventsFiltered.length > 0 ? (
              <List data={eventsFiltered} itemType="events" className="items-container" />
            ) : (
              <p>No upcoming events in {selectedValue}.</p>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default EventsFilterable

EventsFilterable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string
}

EventsFilterable.defaultProps = {
  title: 'Events'
}
