import React from 'react'
import PropTypes from 'prop-types'

import './LinksMenu.css'

const LinksMenu = ({ items, type, handleClick, resetFilter, title, selectedValue }) => {
  const links = items.map(item => (
    <li
      key={item}
      data-id={item}
      data-type={type}
      onClick={handleClick}
      role="button"
      tabIndex={0}
      className={selectedValue === item ? 'links-state-active' : ''}
    >
      {item}
    </li>
  ))

  return (
    <ul className="links-state">
      <li
        onClick={resetFilter}
        data-id="All"
        data-type={type}
        role="button"
        tabIndex={0}
        className={selectedValue.includes('All') ? 'links-state-active' : ''}
      >
        {title}
      </li>
      {links}
    </ul>
  )
}

export default LinksMenu

LinksMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  selectedValue: PropTypes.string
}

LinksMenu.defaultProps = {
  selectedValue: 'All',
  type: 'none'
}
